import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';

// import GIA dependancy
import Component from 'gia/Component';

// Extend the GIA component
export default class Modal extends Component {
	// setup
	constructor(element) {
		super(element);

		this.options = {};

		this.ref = {
			closeBtn: null,
		};
	}

	// mount is equivalent to $('document').ready and will only fire if your component is on the page
	// add event listeners here
	mount() {
		const swiper = new Swiper('.swiper', {
			slidesPerView: "auto",

			spaceBetween: 60,

			//loop: true,

			// If we need pagination

			// Navigation arrows
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},


		});
		// const openBtn = document.getElementById('launch');
		// openBtn.addEventListener('click', this.openModal.bind(this));
		// this.ref.closeBtn.addEventListener('click', this.closeModal.bind(this));
	}
}
