import Component from 'gia/Component';

export default class Nav extends Component {
	constructor(element) {
		super(element);
	}

	mount() {

		var cursor = $('.c-cursor');

		$(function () {
			$('.project-link')
				.mouseover(function () {
					$('.c-cursor')
						.addClass('active')
						.text($(this).data('tooltip'));
				})
				.mouseleave(function () {
					$('.c-cursor').removeClass('active');
				});
		});

		$(window).mousemove(function (e) {
			cursor.css({
				top: e.clientY - cursor.height() / 2,
				left: e.clientX - cursor.width() / 2
			});
		});

		// var btn = document.querySelector('.c-nav .c-button a');
		// btn.addEventListener('mouseover', function () {
		// 	this.textContent = 'Lets Talk! Lets Talk! Lets Talk! Lets Talk! Lets Talk! Lets Talk! ';
		// });
		// btn.addEventListener('mouseout', function () {
		// 	this.textContent = 'Contact';
		// });
	}
}
