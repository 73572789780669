import gsap from 'gsap';
import { MorphSVGPlugin } from '../vendor/MorphSVGPlugin'; // import GIA dependancy
import SplitType from 'split-type';
import Component from 'gia/Component';
gsap.registerPlugin(MorphSVGPlugin);

// Extend the GIA component
export default class Contact extends Component {
	// setup
	constructor(element) {
		super(element);

		this.options = {};

		this.ref = {
			// Header stuff
			home: null,
			contact: null,
			contactClose: null,
			plannerPage: null,
			arrow: null,
			icon: null,

			//Selections
			contactPanel: null,
			plannerPanel: null,
			plannerButton: null,
			planner: null,

			// Save data
			name: null,
			company: null,
			nameValue: null,
			companyValue: null,

			//Slider stuff
			firstPanel: null,
			introNext: null,
			introText: null,
			introInputs: null,

			secondPanel: null,
			secondPanelNext: null,
			secondPanelPrev: null,

			thirdPanel: null,
			thirdPanelNext: null,
			thirdPanelPrev: null,

			//contact stuff
			contactPanel: null,
		};

		const splitted = new SplitType(this.ref.plannerButton, { types: 'chars' });

		gsap.set('#arrow', { rotation: -90, transformOrigin: 'center' });
		gsap.set(this.ref.introText, { opacity: '0' });
		gsap.set(this.ref.introInputs, { opacity: '0' });

		this.tl = new gsap.timeline({ paused: true, revsersed: true, ease: 'power1.easeOut' });
		this.tl.to('#arrow', 0.2, { rotation: 0, transformOrigin: 'center' }).to('#arrow', 0.5, { morphSVG: '#icon' }),
			'+=1s';

		this.tl1 = new gsap.timeline({
			defaults: {
				duration: 0.5,
				ease: 'power1.easeOut',
			},
		});

		// $(window).resize(function () {
		// 	splitted.revert();
		// });
	}

	// mount is equivalent to $('document').ready and will only fire if your component is on the page
	// add event listeners here
	mount() {
		// transtion header show hide
		this.ref.home.addEventListener('click', this.showHeader.bind(this));
		document.getElementById('header').classList.add('hidden');

		this.ref.plannerButton.addEventListener('click', this.showPlanner.bind(this));
		this.ref.plannerButton.addEventListener('click', this.hideContact.bind(this));
		this.ref.contact.addEventListener('click', this.showContactPanel.bind(this));
		this.ref.contactClose.addEventListener('click', this.hideContactPanel.bind(this));

		this.ref.contactClose.classList.toggle('hide');

		//this.ref.contact.addEventListener('click', this.transition.bind(this));
		//this.ref.contact.addEventListener('click', this.transition.bind(this));

		this.ref.home.addEventListener('mouseenter', this.homeMorphIn.bind(this));
		this.ref.home.addEventListener('mouseleave', this.homeMorphOut.bind(this));

		this.ref.introNext.addEventListener('click', this.saveInitialInfo.bind(this));

		this.ref.secondPanelPrev.addEventListener('click', this.secondPanelPrev.bind(this));
		this.ref.secondPanelNext.addEventListener('click', this.secondPaneNext.bind(this));
	}

	showPlanner() {
		this.element.classList.toggle('dark');
		this.tl1
			.to(
				this.ref.contactPanel,
				{
					y: '100vh',
					padding: '0',
					transformOrigin: 'top',
				},
				'+=0'
			)
			.to(
				this.ref.plannerPanel,
				{
					y: '-100vh',
				},
				'-=0.5s'
			)
			.to(
				this.ref.introText,
				{
					opacity: '1',
					delay: '0.3',
				},
				'-=0s'
			)
			.to(
				this.ref.introInputs,
				{
					opacity: '1',
					delay: '0.6',
				},
				'-=0s'
			)
			.to(
				this.ref.contactPanel,
				{
					//	width: '100%',
					duration: 1.5,
				},
				'+=0'
			);

		this.ref.contact.classList.toggle('fade');
	}

	showContactPanel() {
		gsap.to(this.ref.contactPanel, {
			y: '0',
		});
	}

	hideContactPanel() {
		gsap.to(this.ref.contactPanel, {
			y: '100vh',
		});
	}

	hideContact(e) {
		this.ref.contactClose.classList.toggle('hide');
	}


	homeMorphIn(e) {
		this.tl.play();
	}
	homeMorphOut(e) {
		this.tl.reverse();
	}

	hideHeader() {
		document.getElementById('header').classList.add('hidden');
	}
	showHeader() {
		document.getElementById('header').classList.remove('hidden');
	}

	saveInitialInfo() {
		console.log(this.ref.name.value);
		localStorage.setItem('nameValue', this.ref.name.value);
		localStorage.setItem('companyValue', this.ref.company.value);
		this.ref.nameValue.insertAdjacentHTML('beforeend', localStorage.getItem('nameValue'));
		this.ref.companyValue.insertAdjacentHTML('beforeend', localStorage.getItem('companyValue'));
		this.ref.firstPanel.classList.remove('active');
		this.ref.secondPanel.classList.add('active');
	}

	secondPanelPrev() {
		console.log(this.ref.name.value);
		// localStorage.setItem('nameValue', this.ref.name.value);
		// localStorage.setItem('companyValue', this.ref.company.value);
		// this.ref.nameValue.insertAdjacentHTML('beforeend', localStorage.getItem('nameValue'));
		// this.ref.companyValue.insertAdjacentHTML('beforeend', localStorage.getItem('companyValue'));
		this.ref.secondPanel.classList.remove('active');
		this.ref.firstPanel.classList.add('active');
	}

	secondPaneNext() {
		console.log(this.ref.name.value);
		// localStorage.setItem('nameValue', this.ref.name.value);
		// localStorage.setItem('companyValue', this.ref.company.value);
		// this.ref.nameValue.insertAdjacentHTML('beforeend', localStorage.getItem('nameValue'));
		// this.ref.companyValue.insertAdjacentHTML('beforeend', localStorage.getItem('companyValue'));
		this.ref.secondPanel.classList.remove('active');
		this.ref.thirdPanel.classList.add('active');
	}
}
