import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitType from 'split-type';

gsap.registerPlugin(ScrollTrigger);

// import GIA dependancy
import Component from 'gia/Component';

// Extend the GIA component
export default class Headline extends Component {
	// setup
	constructor(element) {
		super(element);

		this.ref = {
			text: null, // looks for single element
			textWrapper: null, // looks for single element
			title: null, // looks for single element
		};
		this.timeline = gsap.timeline({
			defaults: {
				duration: 1,
				ease: 'power3',
			},
		});
		//	const splitted = new SplitType(this.ref.title, { types: 'words' });
	}

	// mount is equivalent to $('document').ready and will only fire if your component is on the page
	// add event listeners here
	mount() {
		let sections = gsap.utils.toArray('[data-scroll]');

		sections.forEach((section) => {
			gsap.timeline({
				scrollTrigger: {
					trigger: section,
					start: 'top center',
					end: 'botom top',
					scrub: true,
					//markers: true,
					toggleClass: 'in-view',
				},
			});

			this.timeline
				.to(this.ref.text, {
					scale: 1,
					delay: 0.5,
					duration: 0.5,
				})
				.to(
					this.ref.textWrapper,
					{
						width: 'auto',
					},
					'-=0.3'
				)
				.to(
					this.ref.text,
					{
						y: 0,
					},
					'-=0.5'
				)
				.to(
					this.ref.text,
					{
						y: 0,
					},
					'-=0.5'
				);
		});


	}
}
