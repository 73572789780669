import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import Swup from 'swup';
//import SwupDebugPlugin from '@swup/debug-plugin';
// import SwupScrollPlugin from '@swup/scroll-plugin';
// import SwupPreloadPlugin from '@swup/preload-plugin';
// import SwupScriptsPlugin from '@swup/scripts-plugin';
//import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupJsPlugin from '@swup/js-plugin';
import SwupRouteNamePlugin from '@swup/route-name-plugin';
import Lenis from '@studio-freight/lenis';
import loadComponents from 'gia/loadComponents';
import components from './components';

gsap.registerPlugin(ScrollTrigger);

// enable components
loadComponents(components);

//swup JSplugin options

// enable swup
const swup = new Swup({
	//	containers: ['#main', '#header'],
	containers: ['#main'],
	cache: false,
	linkSelector:
		'a[href^="' +
		window.location.origin +
		'"]:not([data-no-swup]), a[href^="/"]:not([data-no-swup]), a[href^="./"]:not([data-no-swup]), a[href^="#"]:not([data-no-swup])',
	plugins: [
		//new SwupBodyClassPlugin(),
		new SwupJsPlugin({
			animations: [
				{
					from: '(.*)',
					to: '(.*)',
					in: (next) => {
						window.scrollTo(0, 0);

						setTimeout(next, 1000);
					},
					out: (next) => {
						setTimeout(next, 1000);
					},
				},
			],
		}),
		new SwupRouteNamePlugin({
			routes: [
				{ name: 'home', path: '/:lang?' },
				{ name: 'projects', path: '/projects' },
				{ name: 'project', path: '/projects/:slug' },
				{ name: 'any', path: '(.*)' },
			],
		}),
	],
});

const lenis = new Lenis({
	duration: 1.1,
	easing: (t) => 1 - Math.cos((1 * Math.PI) / 2), // https://www.desmos.com/calculator/brs54l4xou
	direction: 'vertical', // vertical, horizontal
	gestureDirection: 'vertical', // vertical, horizontal, both
	smooth: true,
	mouseMultiplier: 1,
	smoothTouch: false,
	touchMultiplier: 2,
	infinite: false,
});

//get scroll value
lenis.on('scroll', ({ scroll, limit, velocity, direction, progress }) => {});

function raf(time) {
	lenis.raf(time);
	requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

// reload components for each container after transition
swup.hooks.on('content:replace', () => {
	loadComponents(components);
	document.documentElement.classList.add('is-replaced');
});

swup.hooks.on('visit:start', (visit) => {
	document.documentElement.classList.remove('is-replaced');
	document.documentElement.classList.remove('is-ready');
});
swup.hooks.on('visit:end', () => {
	document.documentElement.classList.add('is-replaced');
});

//Initial Load
setTimeout(() => {}, 1000);

setTimeout(() => {
	document.documentElement.classList.add('is-ready');
}, 2000);

let sections = gsap.utils.toArray('[data-scroll]');

sections.forEach((section) => {
	gsap.timeline({
		scrollTrigger: {
			trigger: section,
			start: 'top bottom',
			end: 'bottom top',
			start: 'top top',
			end: '+=1000',
			scrub: true,
			toggleClass: 'in-view',
			//markers: true,
		},
	});
});
// gsap.utils.toArray("section").forEach(function (elem) {

// 	var color = elem.getAttribute('data-bg');

// 	ScrollTrigger.create({
// 		trigger: elem,
// 		start: 'top bottom',
// 		end: 'bottom top',
// 		toggleClass: { targets: "body", className: color },
// 		markers: true
// 	});

// });
