import Component from 'gia/Component';

export default class BaseTheme extends Component {

	// setup
	constructor(element) {
		super(element);

		// set default options if required
		// these can be overridden by setting options in your html, eg.
		// <div class="c-Example" g-component="Example" g-options='{"someOption": "customValue"}'>
		this.options = {
			someOption: "defaultValue",
			transition: "swipe"
		};

		// use refs to quickly select elements with the component, eg.
		// <h1 class="c-Example__title" g-ref="title">Six</h1>
		// you need to define them here... 
		this.ref = {
			title: null, // looks for single element
			slide: [], // looks for multiple elements
			expandToggle: null
		}
	}

	// mount is equivalent to $('document').ready and will only fire if your component is on the page
	// add event listeners here
	mount() {

		console.log('base installed')

		// const inview = gsap.utils.toArray('[data-scroll]');
		// inview.forEach((inview) => {
		// 	gsap.from(inview, {
		// 		scrollTrigger: {
		// 			start: 'top bottom',
		// 			end: 'bottom top',
		// 			trigger: inview,
		// 			toggleClass: 'in-view'
		// 		}
		// 	});
		// });


	}
}