import BaseTheme from './components/basetheme';
import Observer from './classes/Observer';
import Hero from './components/c-Hero';
import Nav from './components/c-Nav';
import Headline from './components/c-Headline';
import Projects from './components/c-Projects';
import HorizontalScroll from './components/c-HorizontalScroll';
import Services from './components/c-Services';
import Cursor from './components/c-Cursor';
import Modal from './components/c-Modal';
import Contact from './components/c-Contact';
import cardSlider from './components/c-cardSlider';

const components = {
	BaseTheme,
	Observer,
	Hero,
	Nav,
	Headline,
	Projects,
	HorizontalScroll,
	Services,
	Cursor,
	Modal,
	Contact,
	cardSlider,
};

export default components;
