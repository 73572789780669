import Component from 'gia/Component';

export default class Observer extends Component {
	constructor({ element }) {
		this.element = element;

		this.createObserver();
	}

	createObserver() {
		const options = {
			rootMargin: '0px',
			threshold: 0.5,
		};
		this.observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					console.log('in view');
					this.onEnter();
				} else {
					console.log('not in view');
					this.onLeave();
				}
			});
		}, options);

		this.observer.observe(this.element);
		console.log('observer working');
	}

	onEnter() {}

	onLeave() {}
}
