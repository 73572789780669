import SplitType from 'split-type';
import gsap from 'gsap';

import Component from 'gia/Component';

// Extend the GIA component
export default class Services extends Component {
	constructor(element) {
		super(element);

		this.ref = {
			service: [], // looks for single element
			serviceDescription: [], // looks for single element
		};
		const splitted = new SplitType(this.ref.service, { types: 'chars' });
		const splitteded = new SplitType(this.ref.serviceDescription, { types: 'lines' });
	}

	mount() {
		//this.ref.serviceTop.addEventListener('mouseover', onMouseOver);
		document.querySelectorAll('.c-service--item')[0].classList.add('active');
		document.querySelectorAll('.c-service--item--description')[0].classList.add('active');

		const services = document.querySelector('.c-services');
		const servicesItem = document.querySelectorAll('.c-service--item');
		const servicesContent = document.querySelectorAll('.c-service--item--description');
		services.addEventListener('click', (e) => {
			const id = e.target.dataset.id;
			if (id) {
				servicesItem.forEach((btn) => {
					btn.classList.remove('active');
				});
				e.target.classList.add('active');
				servicesContent.forEach((content) => {
					content.classList.remove('active');
				});
				const element = document.getElementById(id);
				element.classList.add('active');
			}
		});
	}

	onMouseOver(event) {
		var trg = event.target;
		if (trg.tagName.toLowerCase() === 'span') {
			var tl = new TimelineLite();

			tl.to(trg, 1, { yPercent: -50, ease: Power2.easeInOut });
			tl.to(trg, 1, { yPercent: 0, ease: Power2.easeInOut });
		}
	}
}
