import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Component from 'gia/Component';

gsap.registerPlugin(ScrollTrigger);

export default class HorizontalScroll extends Component {
	constructor(element) {
		super(element);
		this.ref = {
			// line: [], // looks for single element
			// title: null, // looks for single element
			// imageWrap: null, // looks for single element
			// video: null, // looks for single element
		};

		//gsap.set(this.ref.imageWrap, { height: 0 });
	}

	mount() {
		// const cards = gsap.utils.toArray(".card");

		// const cardsWrapper = document.querySelector(".cards-wrapper");

		// gsap.to(cardsWrapper, {
		// 	x: () => window.innerWidth - cardsWrapper.scrollWidth,
		// 	ease: "none",
		// 	scrollTrigger: {
		// 		trigger: ".container",
		// 		start: "top top",
		// 		end: "+=" + (cards.length / 2) * 100 + "%",
		// 		scrub: true,
		// 		pin: true,
		// 		markers: true
		// 	}

		// })
		let sections = gsap.utils.toArray('.card');

		let scrollTween = gsap.to(sections, {
			xPercent: -100 * (sections.length - 1),
			ease: 'none', // <-- IMPORTANT!
			scrollTrigger: {
				trigger: '.container',
				pin: true,
				scrub: 0.1,
				//snap: directionalSnap(1 / (sections.length - 1)),
				end: '+=3000',
			},
		});
		``;
	}
}
