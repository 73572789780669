import gsap from 'gsap';
import Flip from 'gsap/Flip';
gsap.registerPlugin(Flip);

// import GIA dependancy
import Component from 'gia/Component';

// Extend the GIA component
export default class Projects extends Component {
	// setup
	constructor(element) {
		super(element);

		this.ref = {
			project: [], // looks for single element
		};
	}

	// mount is equivalent to $('document').ready and will only fire if your component is on the page
	// add event listeners here
	mount() {
		const projects = gsap.utils.toArray(this.ref.project);
		projects.forEach((project) => {
			project.addEventListener('click', () => {
				const state = Flip.getState(project);
				project.classList.toggle('full-screen');
				Flip.from(state, {
					duration: 0.5,
					//ease: 'power1.inOut',
					//absolute: true,
					//onComplete: myFunc,
				});
			});
		});
	}
}
