// import GIA dependancy
import Component from 'gia/Component';

// Extend the GIA component
export default class Modal extends Component {
	// setup
	constructor(element) {
		super(element);

		this.options = {};

		this.ref = {
			closeBtn: null,
		};
	}

	// mount is equivalent to $('document').ready and will only fire if your component is on the page
	// add event listeners here
	mount() {
		// const openBtn = document.getElementById('launch');
		// openBtn.addEventListener('click', this.openModal.bind(this));
		// this.ref.closeBtn.addEventListener('click', this.closeModal.bind(this));
	}

	openModal() {
		this.element.classList.add('active');
	}
	closeModal() {
		this.element.classList.remove('active');
	}
}
