import Component from 'gia/Component';

export default class Nav extends Component {
	constructor(element) {
		super(element);
	}

	mount() {
		var btn = document.querySelector('.c-nav .c-button a');

		btn.addEventListener('mouseover', function () {
			this.textContent = 'Lets Talk! Lets Talk! Lets Talk! Lets Talk! Lets Talk! Lets Talk! ';
		});
		btn.addEventListener('mouseout', function () {
			this.textContent = 'Contact';
		});
	}
}
