import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import Component from 'gia/Component';

gsap.registerPlugin(ScrollTrigger);

export default class Hero extends Component {
	constructor(element) {
		super(element);
		this.ref = {
			line: [], // looks for single element

			title: null, // looks for single element
			imageWrap: null, // losoks for single element
			video: null, // looks for single element
		};

		const matches = this.ref.title.querySelectorAll('span:nth-of-type(odd)');

		gsap.set(this.ref.imageWrap, { height: 0 });
		gsap.set(this.ref.title, { maxWidth: '80rem' });
		gsap.set(this.matches, { color: 'green' });
		gsap.set(this.ref.lineEven, { color: 'red' });
	}

	mount() {
		//prettier-ignore

		var tl = gsap.timeline({
			defaults: {
				duration: 1,
				ease: "power1.easeOut",
			},
		});

		tl.fromTo(
			this.ref.title,
			{
				opacity: 0,
				delay: 0.1,
				duration: 2,
				maxWidth: '80rem',
			},
			{
				opacity: 1,
				delay: 1.2,
				duration: 1.2,
				maxWidth: '70rem',
			}
		);

		var tl1 = gsap.timeline({
			scrollTrigger: {
				trigger: '.l-hero--home',
				start: 'top top',
				end: '+=1000',
				scrub: 0.6,
				//markers: true,
				pin: true,
			},
		});

		tl1.to(this.ref.imageWrap, { height: '100vh', duration: 1 });
		tl1.to(this.ref.title, { maxWidth: '100%', duration: 1 });

		tl1.to(
			this.ref.line,
			{
				opacity: '0',
				delay: 0,
				duration: 0.1,
				//ease: 'none'
			},
			'-=1'
		);
		tl1.scrollTrigger.refresh();

	}
}
